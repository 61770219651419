<template>
  <div>
    <v-layout class="p-4 pr-0 border-bottom-light-grey min-height-57px">
      <v-flex class="d-flex font-level-3-bold my-auto">
        <span class="detail-svg-icon mr-2">
          <!--begin::Svg Icon-->
          <span class="svg-icon svg-icon-lg mr-2 svg-v-list-icon">
            <inline-svg :src="$assetURL('media/custom-svg/attachment.svg')" />
          </span>
          <!--end::Svg Icon-->
        </span>
        <h1 class="form-title font-size-20 font-weight-700">
          <template v-if="typeText">
            {{ typeText }}
          </template>
          <template v-else> Files </template>
        </h1>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          v-if="typeText != 'visit' && typeText != 'Order Attachments'"
          v-on:click="add_document_dialog = true"
          color="cyan text-white"
          class="ml-2"
          depressed
          tile
          ><v-icon small left>mdi-playlist-plus</v-icon>Attach File
        </v-btn>
      </v-flex>
    </v-layout>
    <div class="overflow-y">
      <v-row>
        <template v-if="dbFiles?.length">
          <v-col
            md="12"
            v-for="(row, index) in dbFiles"
            :key="index"
            class="border-bottom d-flex"
            :class="{
              'custom-border-top': index == 0,
              'orange_lighten-5': index % 2 == 0,
            }"
          >
            <div class="px-4" style="width: 60px">
              <template v-if="row.extension && isImage(row.extension)">
                <ImageTemplate
                  :src="row.file.url"
                  style="max-width: 50px; width: 50px"
                ></ImageTemplate>
              </template>
              <template v-else>
                <inline-svg
                  style="max-width: 50px; width: 50px"
                  :src="$assetURL(`media/mime/${row.extension}.svg`)"
                />
              </template>
            </div>

            <div :style="get_col_style(extensive)" class="px-4 text-truncate">
              <b
                class="blue--text text--darken-4 cursor-pointer"
                v-on:click="doAction(row, 'download')"
                >{{ row.name }}</b
              >
              <b v-if="row.type_in_module" class="ml-3 red--text"
                >({{ row.type_in_module }} - {{ row.module_type_parent }} )</b
              >
              <div>
                <b>Size:</b>{{ Number(Number(row.size) / 1024).toFixed(2) }}KB
              </div>
              <div
                v-if="
                  (row &&
                    row.type &&
                    (row.type == 2 ||
                      row.type == 251 ||
                      row.type == 250 ||
                      row.type == 302 ||
                      row.type == 301)) ||
                  row.type == 5005 ||
                  row.type == 5006 ||
                  row.type == 5007 ||
                  row.type == 5008 ||
                  row.type == 5015 ||
                  row.type == 5021 ||
                  row.type == 5022 ||
                  row.type == 5023
                "
              >
                <v-chip
                  :color="getTypeColor(row.type)"
                  outlined
                  text-color=""
                  label
                  small
                >
                  {{ getTypeName(row.type) }}</v-chip
                >
              </div>
            </div>
            <div
              style="width: 30%"
              class="px-4 d-flex flex-wrap"
              v-if="extensive"
            >
              <div class="mr-10">
                <div>
                  <b class="d-inline-block" style="min-width: 64px">Start </b
                  >:&nbsp;&nbsp;
                  <span v-if="row?.start_date">
                    {{ formatDate(row.start_date) }}
                  </span>
                  <em class="text-muted" v-else> no start </em>
                </div>
                <div>
                  <b class="d-inline-block" style="min-width: 64px">End </b
                  >:&nbsp;&nbsp;
                  <span v-if="row?.end_date">
                    {{ formatDate(row.end_date) }}
                  </span>
                  <em class="text-muted" v-else> no end </em>
                </div>
                <div>
                  <b class="d-inline-block" style="min-width: 64px">Reminder </b
                  >:&nbsp;&nbsp;
                  <span v-if="row?.reminder_date">
                    {{ formatDate(row.reminder_date) }}
                  </span>
                  <em class="text-muted" v-else> no reminder </em>
                </div>
              </div>
            </div>
            <div style="width: 20%" class="px-4" v-if="extensive">
              <template v-if="row.tags && row.tags?.length > 0">
                <v-chip
                  small
                  v-for="(tagRow, index) in row.tags"
                  :key="index"
                  class="mr-2 mb-1"
                  text-color="white"
                  :color="tagRow?.color"
                  >{{ tagRow.tag }}</v-chip
                >
              </template>
              <em v-else class="text-muted"> no tags</em>
            </div>
            <div style="width: 15%" class="px-4 d-flex flex-wrap">
              <div class="mr-0">
                <div>
                  <v-chip
                    v-if="row.user_display_name"
                    class="mx-2 my-1 custom-grey-border"
                    color="blue-grey darken-1"
                    text-color="white"
                    style="height: 25px !important"
                  >
                    <v-avatar left>
                      <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                    <p class="m-0 custom-nowrap-ellipsis">
                      {{ row.user_display_name }}
                    </p>
                  </v-chip>
                  <em class="text-muted" v-else> no Created by </em>
                </div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-if="row.added_at"
                      class="mx-2 my-1 custom-grey-border"
                      color="blue-grey darken-1"
                      text-color="white"
                      v-bind="attrs"
                      v-on="on"
                      style="height: 25px !important"
                    >
                      <v-avatar left>
                        <v-icon>mdi-clock-outline</v-icon>
                      </v-avatar>
                      <p class="m-0 custom-nowrap-ellipsis">
                        {{ formatDate(row.added_at) }}
                      </p>
                    </v-chip>
                  </template>
                  <p class="m-0 custom-nowrap-ellipsis">
                    {{ formatDate(row.added_at) }}
                    {{ formatDateStartedNew(row.added_at) }}
                  </p>
                </v-tooltip>
              </div>
            </div>
            <div style="width: 10%" class="px-4 d-flex">
              <v-spacer></v-spacer>
              <v-btn
                v-on:click="doAction(row, 'download')"
                small
                icon
                depressed
                color="blue darken-4"
                class="mx-1"
              >
                <v-icon medium>mdi-download</v-icon>
              </v-btn>
              <v-btn
                v-on:click="doAction(row, 'delete')"
                icon
                small
                depressed
                class="mx-1"
                color="red lighten-1"
                ><v-icon medium>mdi-delete</v-icon></v-btn
              >
            </div>
          </v-col>
        </template>
        <template v-else>
          <v-col class="text-center border-top">
            <p class="m-0 py-15 row-not-found text-center">
              <img
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image"
              />
              Uhh... There are no file at the moment.
            </p>
          </v-col>
        </template>
      </v-row>
    </div>
    <DocumentwithName
      :document-dialog="add_document_dialog"
      v-on:close="add_document_dialog = false"
      v-on:success="getFiles()"
      :document-type="type"
      :document-id="typeId"
      :extensive="extensive"
      :parent-type="typeParent"
      :parent-type-id="typeParentId"
      :tag-type="tagType"
    >
    </DocumentwithName>
    <Dialog :commonDialog="deleteDialog" :dialog-width="600">
      <template v-slot:title> Delete File</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/attention-circle.svg')"
              />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              Deleting File is irreversible, Are you sure about deleting it?
            </p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="deleteLoading"
          :disabled="deleteLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteFile()"
        >
          Yes! Delete
        </v-btn>
        <v-btn
          depressed
          tile
          :disabled="deleteLoading"
          v-on:click="deleteDialog = false"
        >
          No, Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { toNumber, round, find } from "lodash";
import ImageTemplate from "@/view/pages/Image";
//import Dialog from "@/view/components/Dialog";
import Dialog from "@/view/pages/partials/Dialog.vue";
//import TextAreaInput from "@/view/components/TextAreaInput";
import ApiService from "@/core/services/api.service";
import DocumentwithName from "@/view/pages/DocumentwithName";
//import { EventBus } from "@/core/event-bus/event.bus";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  props: {
    tagType: {
      type: String,
      default: null,
    },
    typeText: {
      type: String,
      default: null,
    },
    type: {
      type: Number,
      default: null,
    },
    typeParent: {
      type: String,
      default: null,
    },
    typeUuid: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: null,
    },
    typeParentId: {
      type: Number,
      default: null,
    },
    reload: {
      type: Boolean,
      default: false,
    },
    extensive: {
      type: Boolean,
      default: false,
    },
    is_main: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    reload(param) {
      if (param) {
        this.getFiles();
      }
    },
    typeId(param) {
      if (param) {
        this.getFiles();
      }
    },
  },
  data() {
    return {
      pageLoading: true,
      deleteLoading: false,
      deleteDialog: false,
      attachDialog: false,
      attachLoading: false,
      uuid: null,
      description: null,
      files: [],
      dbFiles: [],
      add_document_dialog: false,
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
    };
  },
  methods: {
    get_col_style(param) {
      let width = 65;
      if (param) {
        width = 30;
      }
      return { width: `${width}%` };
    },
    formatDateStartedNew(param) {
      return moment(param).format("hh:mm A");
    },
    getReminderDateClass(endDate, reminderDate) {
      const end = new Date(endDate);
      const reminder = new Date(reminderDate);
      return reminder > end ? "text-danger" : "";
    },
    init() {
      this.deleteLoading = false;
      this.deleteDialog = false;
      this.attachDialog = false;
      this.attachLoading = false;
      this.uuid = null;
      this.description = null;
      this.files = [];
    },
    getTypeName(type) {
      if (type == 2) {
        return "Internal";
      } else if (type == 5006 || type == 5018 || type == 5022) {
        return "Rejected";
      } else if (type == 5005 || type == 5017) {
        return "Accepted";
      } else if (type == 301) {
        return "Admin use only";
      } else if (type == 5007 || type == 5019) {
        return "Approved";
      } else if (type == 5008 || type == 5020) {
        return "UnApproved";
      } else if (type == 302) {
        return "Internal use only";
      } else if (type == 5021) {
        return "Confirmed";
      } else if (type == 5023) {
        return "Confirmed Based On Quote";
      } else if (type == 5015) {
        return "Terminated";
      }
    },
    getTypeColor(type) {
      if (type == 2) {
        return "#24326d";
      } else if (type == 5006 || type == 5018 || type == 5022) {
        return "red";
      } else if (type == 5005 || type == 5017 || type == 5021 || type == 5023) {
        return "green";
      } else if (type == 5008 || type == 5020) {
        return "red";
      } else if (type == 5007 || type == 5019) {
        return "blue";
      } else if (type == 301) {
        return "red";
      } else if (type == 302) {
        return "green";
      } else if (type == 5015) {
        return "red";
      }
    },
    updateFiles(param) {
      if (param) {
        const max_size = toNumber(this.$uploadSizeLIMIT);
        const max_file = toNumber(this.$uploadLIMIT);
        for (let i = 0; i < param.length; i++) {
          const size = round(toNumber(param[i].size / 1024), 2);
          if (size <= max_size && this.files.length < max_file) {
            this.files.push(param[i]);
          }
        }
      }
    },
    getFileSize(size) {
      return round(toNumber(size) / 1024, 2);
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.$refs["upload-file"].value = null;
    },
    maxFileSize() {
      return round(toNumber(this.$uploadSizeLIMIT) / 1024, 2);
    },
    selectFile() {
      this.$refs["upload-file"].reset();
      this.$refs["upload-file"].$refs["input"].value = null;
      this.$nextTick(() => {
        this.$refs["upload-file"].$refs["input"].click();
      });
    },
    doAction(row, param) {
      switch (param) {
        case "download":
          window.open(row.file.url, "_blank");
          break;
        case "delete":
          this.id = row.id;
          this.deleteDialog = true;
          break;
      }
    },
    deleteFile() {
      if (!this.id) {
        return false;
      }
      this.deleteLoading = true;
      ApiService.setHeader();
      ApiService.delete(`delete-documents/${this.id}`)

        .then(() => {
          this.getFiles();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    getFiles() {
      ApiService.setHeader();
      ApiService.get(`documents/${this.type}/${this.typeId}`)

        .then(({ data }) => {
          this.dbFiles = data.data;
          this.init();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },

    attachFile() {
      const params = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        params.append(`file[${i}]`, this.files[i]);
      }

      params.append("description", this.description);

      this.attachLoading = true;

      ApiService.setHeader();
      ApiService.upload(`${this.type}/file`, params)
        .then(() => {
          this.getFiles();
          this.$refs["upload-file"].value = null;
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.attachLoading = false;
        });
    },
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    checkWExpDate(date) {
      /* let currentData = MomentJS();
			let enddate = MomentJS(date, "DD/MM/YYYY");
			console.log(enddate.diff(currentData, "days"));
			if (enddate.diff(currentData, "days") > 0) {
				return false;
			}
			return true; */

      let currentData = moment();
      let enddate = moment(date, "YYYY-MM-DD");
      if (enddate.diff(currentData, "days") >= 0) {
        return false;
      }
      return true;
    },
  },
  components: {
    Dialog,
    //	TextAreaInput,
    ImageTemplate,
    DocumentwithName,
  },
  mounted() {
    console.log(this.type);
    if (this.typeId > 0) {
      this.getFiles();
    }

    // EventBus.$on("reload:asset", () => {
    // 	this.getFiles();
    // });
  },
};
</script>
<style scoped>
.custom-min-height-60vh {
  height: 77vh;
}
.v-window {
  overflow: auto !important;
}
.v-chip.v-size--default.tag {
  height: auto !important;
  padding: 1px 8px !important;
}
</style>
<style>
#attachment .overflow-y {
  max-height: none !important;
}
.text-danger {
  color: red;
}
</style>
