<template>
  <div class="pr-2">
    <template v-if="v2ViewLineItem?.length > 0">
      <div class="my-3 text-h5 color-custom-blue">Line Item</div>

      <div style="max-width: 100%; overflow-x: scroll">
        <div class="" style="width: 2300px">
          <div
            class="d-flex grey lighten-4 px-0 costing-item-header"
            style="border-bottom: solid 1px #e4e3e3 !important"
          >
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 2%"
            >
              #
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Brand
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Model
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Part
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Product
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              Currency
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              Cost
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 3%"
            >
              FLT
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              XR
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              GEAR
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 4%"
            >
              LAMP
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 3%"
            >
              ACC
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Unit Cost
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Markup %
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 6%"
            >
              Unit Selling
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              REQ. QTY
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Pro. QTY
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 3%"
            >
              UOM
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 7%"
            >
              Pro. Unit Sell.
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Total Cost
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 5%"
            >
              Total Sell.
            </div>
            <div
              class="color-custom-blue py-2 font-weight-500 text-center"
              style="width: 6%"
            >
              % Total Sales
            </div>
          </div>
          <div
            v-for="(row, index) in v2ViewLineItem"
            class="top-row"
            :key="`line-item-drag-${index}`"
          >
            <div
              :id="`line-item-drag-${index}`"
              class="d-flex line-item-drag group_row"
            >
              <div
                class="font-weight-600 border-left border-right d-flex"
                style="width: 100%"
              >
                <div class="mt-0 px-1" style="font-size: 15px">
                  {{ row?.group_name }}
                </div>
                <!-- <v-spacer></v-spacer>
                <v-checkbox
                  class="custom-box mr-1"
                  hide-details
                  color="cyan"
                  disabled
                  v-model="row.isHeader"
                  label="Show in PDF"
                /> -->
              </div>
            </div>
            <div
              v-for="(children, cindex) in row?.children"
              :key="`line-item-drag-${cindex}`"
            >
              <div class="d-flex line-item-drag">
                <div
                  class="font-weight-600 border-left border-right"
                  style="width: 100%"
                >
                  <div class="mt-0 px-1" style="font-size: 15px">
                    {{ children.Subheader }}
                  </div>
                </div>
              </div>
              <div
                v-for="(header_item, ccindex) in children?.header_item"
                :key="ccindex"
                class="d-flex line-item-drag"
              >
                <div
                  class="font-weight-600 text-center border-left border-right"
                  style="width: 2%; background: #f5f5f5; z-index: 9"
                >
                  <div class="mt-0" style="font-size: 15px">
                    {{ ccindex + 1 }}.
                  </div>
                </div>
                <div
                  class="text-center border-right text-capitalize"
                  style="width: 5%"
                >
                  <div class="">
                    {{ header_item?.brand }}
                  </div>
                </div>
                <div
                  class="text-center border-right text-capitalize"
                  style="width: 5%"
                >
                  <div class="" style="word-break: break-word">
                    {{ header_item?.model }}
                  </div>
                </div>
                <div
                  class="text-center border-right text-capitalize"
                  style="width: 5%"
                >
                  <div class="">
                    {{ header_item?.part }}
                  </div>
                </div>
                <div
                  class="text-center border-right text-capitalize"
                  style="width: 5%"
                >
                  <div class="">
                    {{ header_item?.product }}
                  </div>
                </div>
                <div
                  class="text-center border-right text-uppercase"
                  style="width: 4%"
                >
                  <div class="">
                    {{ header_item?.cost_currency }}
                  </div>
                </div>
                <div class="text-center border-right" style="width: 4%">
                  <div class="">
                    {{ formatMoney(header_item?.cost) }}
                  </div>
                </div>
                <div class="text-center border-right" style="width: 3%">
                  <div class="">
                    {{ formatMoney(header_item?.flt) }}
                  </div>
                </div>
                <div class="text-center border-right" style="width: 4%">
                  <div class="">
                    {{ formatMoney(header_item?.xr) }}
                  </div>
                </div>
                <div class="text-center border-right" style="width: 4%">
                  <div class="">
                    {{ formatMoney(header_item?.gear) }}
                  </div>
                </div>
                <div class="text-center border-right" style="width: 4%">
                  <div class="">
                    {{ formatMoney(header_item?.lamp) }}
                  </div>
                </div>
                <div class="text-center border-right" style="width: 3%">
                  <div class="">
                    {{ formatMoney(header_item?.acc) }}
                  </div>
                </div>
                <div class="text-center border-right" style="width: 5%">
                  <div class="text-center border-right">
                    {{ formatMoney(header_item?.unit_cost) }}
                  </div>
                </div>
                <div class="text-center border-right" style="width: 5%">
                  <div class="text-center">
                    <span>{{ formatMoney(header_item?.margin) }}</span>
                  </div>
                </div>
                <div class="text-center border-right" style="width: 6%">
                  <div class="text-center border-right">
                    <span>{{ formatMoney(header_item?.unit_selling) }}</span>
                  </div>
                </div>
                <div class="text-center border-right" style="width: 5%">
                  <div class="text-center">
                    <span>{{
                      formatMoney(header_item?.required_quantity)
                    }}</span>
                  </div>
                </div>
                <div class="text-center border-right" style="width: 5%">
                  <div class="text-center border-right">
                    <span>{{ formatMoney(header_item?.proquantity) }}</span>
                  </div>
                </div>
                <div class="text-center border-right" style="width: 3%">
                  <div class="text-center border-right">
                    <span>{{ header_item?.uom }}</span>
                  </div>
                </div>
                <div class="text-center border-right" style="width: 7%">
                  <div class="text-center border-right">
                    <span>{{
                      formatMoney(header_item?.pro_unit_selling)
                    }}</span>
                  </div>
                </div>
                <div class="text-center border-right" style="width: 5%">
                  <div class="text-center">
                    <span>{{ formatMoney(header_item?.total_cost) }}</span>
                  </div>
                </div>
                <div class="text-center border-right" style="width: 5%">
                  <span>{{ formatMoney(header_item?.total_selling) }}</span>
                </div>
                <div class="text-center border-right" style="width: 6%">
                  <div class="text-center">
                    {{ formatMoney(header_item?.total_sales) }} %
                  </div>
                </div>
              </div>
              <div
                class="d-flex line-item-dra"
                v-if="children?.header_item?.length != 0"
              >
                <div
                  class="border-left text-end border-right"
                  style="width: 77%"
                >
                  <div class="mt-0 px-1" style="font-size: 15px">
                    <b>Total</b>
                  </div>
                </div>

                <div class="border-right text-center" style="width: 7%">
                  <div class="mt-0 px-1" style="font-size: 15px">
                    {{ formatMoney(children?.p_unit_selling) }}
                  </div>
                </div>
                <div class="border-right text-center" style="width: 5%">
                  <div class="mt-0 px-1" style="font-size: 15px">
                    {{ formatMoney(children?.p_total_cost) }}
                  </div>
                </div>
                <div class="border-right text-center" style="width: 5%">
                  <div class="mt-0 px-1" style="font-size: 15px">
                    <b>{{ formatMoney(children?.p_total_saling) }}</b>
                  </div>
                </div>
                <div class="border-right text-center" style="width: 6%">
                  <div class="mt-0 px-1" style="font-size: 15px">
                    {{ formatMoney(children?.p_total_sales) }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 pr-2 font-weight-600">
        <table width="100%">
          <tr>
            <td width="66%" class="py-1"></td>
            <td width="18%" align="right" class="py-1">Total Selling</td>
            <td width="18%" align="right" class="py-1">
              {{ formatMoney(v2ViewCalculation?.total_selling) }}
            </td>
          </tr>
          <tr>
            <td width="66%" class="py-1"></td>
            <td width="18%" align="right" class="py-1">Total Cost</td>
            <td width="18%" align="right" class="py-1">
              {{ formatMoney(v2ViewCalculation?.total_cost_value) }}
            </td>
          </tr>
          <tr>
            <td width="66%" class="py-1"></td>
            <td width="18%" align="right" class="py-1">Total Margin</td>
            <td width="18%" align="right" class="py-1">
              {{ v2ViewCalculation?.total_margin }}%
            </td>
          </tr>
          <tr>
            <td class="py-1"></td>
            <td align="right" class="py-1">
              Percentage Discount
              <span v-if="v2ViewCalculation?.discount_type == 1"
                >({{ formatMoney(v2ViewCalculation?.discount_value) }})</span
              >
              <span v-if="v2ViewCalculation?.discount_type == 2"
                >({{ formatNumber(v2ViewCalculation?.discount_value) }}%)</span
              >
            </td>
            <td align="right" class="py-1">
              {{ formatMoney(v2ViewCalculation?.main_discount) }}
            </td>
          </tr>
          <tr>
            <td class="py-1"></td>
            <td align="right" class="py-1">
              Lump Sum Discount
              <span v-if="v2ViewCalculation?.lump_discount_type == 1"
                >({{
                  formatMoney(v2ViewCalculation?.lump_discount_value)
                }})</span
              >
              <span v-if="v2ViewCalculation?.lump_discount_type == 2"
                >({{
                  formatNumber(v2ViewCalculation?.lump_discount_value)
                }}%)</span
              >
            </td>
            <td align="right" class="py-1">
              {{ formatMoney(v2ViewCalculation?.lump_sum_discount) }}
            </td>
          </tr>
          <tr class="font-size-19">
            <td class="py-1"></td>
            <td class="py-1" align="right">Grand Total</td>
            <td class="py-1 font-weight-bold color-custom-blue" align="right">
              {{ formatMoney(v2ViewCalculation?.grand_total) }}
            </td>
          </tr>
        </table>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-center justify-center mt-2">
        <img
          :src="$assetURL('media/error/empty.png')"
          class="row-not-found-image"
        />
        Uhh... There are no line items at the moment.
      </div>
    </template>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { mapGetters } from "vuex";
export default {
  mixins: [CommonMixin],
  name: "visit-line-item",
  props: {
    relatedId: {
      type: Number,
      default: 0,
    },

    isQuotation: {
      type: Boolean,
      default: false,
    },
    isCosting: {
      type: Boolean,
      default: false,
    },
    relatedType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      db_line_items2: [
        {
          group_name: "Group name 1",
          group_item_name: "Group Item 1",
          item_feature: [
            {
              id: 1,
              brand: "JRLite",
              product: "Brand",
              model: "COVEFLEX1808",
              part_name: "RURE IQ LED STRIPS",
              currency: "SGD",
              cost: 15.8,
              flt: 1.2,
              xr: 1.5,
              gear: null,
              lamp: null,
              acc: 5.0,
              item_cost: 33.44,
              uom: "meters",
              required_quantity: 20,
              proquantity: 20,
              margin: "30.00%",
              unit_selling: 47.77,
              pro_unit_selling: 47.77,
              item_selling: null,
              total_cost: 668.8,
              total_selling: 960.0,
              total_sales: 1.24,
              total: 0,
            },
          ],
          total_cost_value: 1280,
          total_margin: 34.0,
          discounted_total_selling: 8870.5,
          total_selling: 139520,
        },
        {
          group_name: "Group name 1",
          group_item_name: "Group Item 1",
          item_feature: [
            {
              id: 1,
              brand: "JRLite",
              product: "Brand",
              model: "COVEFLEX1808",
              part_name: "RURE IQ LED STRIPS",
              currency: "SGD",
              cost: 15.8,
              flt: 1.2,
              xr: 1.5,
              gear: null,
              lamp: null,
              acc: 5.0,
              item_cost: 33.44,
              uom: "meters",
              required_quantity: 20,
              proquantity: 20,
              margin: "30.00%",
              unit_selling: 47.77,
              pro_unit_selling: 47.77,
              item_selling: null,
              total_cost: 668.8,
              total_selling: 960.0,
              total_sales: 1.24,
              total: 0,
            },
            {
              id: 2,
              brand: "JRLite",
              product: "Brand",
              model: "COVEFLEX1808",
              part_name: "RURE IQ LED STRIPS",
              currency: "SGD",
              cost: 15.8,
              flt: 1.2,
              xr: 1.5,
              gear: null,
              lamp: null,
              acc: 5.0,
              item_cost: 33.44,
              uom: "meters",
              required_quantity: 20,
              proquantity: 20,
              margin: "30.00%",
              unit_selling: 47.77,
              pro_unit_selling: 47.77,
              item_selling: null,
              total_cost: 668.8,
              total_selling: 960.0,
              total_sales: 1.24,
              total: 0,
            },
          ],
          total_cost_value: 1280,
          total_margin: 34.0,
          discounted_total_selling: 8870.5,
          total_selling: 139520,
        },
      ],
      discount_type_list: [
        { type: "%", value: "2" },
        { type: "$", value: "1" },
      ],
      sno: 1,
      loading: false,
    };
  },
  methods: {},
  mounted() {},
  computed: {
    ...mapGetters(["v2ViewLineItem", "v2ViewCalculation"]),
  },
  components: {},
};
</script>
<style scoped>
/* .line-item-drag.top-row {
  width: 2000px;
  overflow-x: auto;
} */

.group_row {
  background-color: #faf3e8;
}
.fixed-td.table tbody td {
  padding: 4px !important;
}
.fixed-td.table thead tr th:first-child {
  position: sticky;
  left: 0;
}
.fixed-td.table thead tr th:nth-child(2) {
  position: sticky;
  left: 46px;
}
.costing-item-header div:first-child {
  position: sticky;
  left: 0;
  background: #f5f5f5;
}
.costing-item-header div:nth-child(2) {
  position: sticky;
  left: 46px;
  background: #f5f5f5;
}
.costing-item-header div:nth-child(3) {
  position: sticky;
  left: 161px;
  background: #f5f5f5;
}
.costing-item-header div:nth-child(4) {
  position: sticky;
  left: 276px;
  background: #f5f5f5;
}
.line-item-drag div:nth-child(1) {
  position: sticky;
  left: 0;
}
.line-item-drag div:nth-child(2) {
  position: sticky;
  left: 46px;
  background: #f5f5f5;
  z-index: 9;
}
.line-item-drag div:nth-child(3) {
  position: sticky;
  left: 161px;
  background: #f5f5f5;
  z-index: 9;
}
.line-item-drag div:nth-child(4) {
  position: sticky;
  left: 276px;
  background: #f5f5f5;
  z-index: 9;
}
.group_row div:first-child {
  position: sticky;
  left: 0;
}
</style>
